















































































































































































































































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { ObserveVisibility } from 'vue-observe-visibility';
import { EmbeddedContent, EmbeddedContentType } from 'client-website-ts-library/types/EmbeddedContent/EmbeddedContent';

import { ItemCyclerImageSlide, ItemCyclerSlide } from '@/app_code/ItemCycler';
import ItemCycler from '@/components/Cycler/ItemCycler.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import Embeddable from '@/components/Common/Embeddable.vue';
import Form from '@/components/Forms/Form.vue';
import { FormConstructorData } from 'client-website-ts-library/types/Forms';
import FranchiseOpportunityCard from '@/components/FranchiseOpportunityCard.vue';
import { Context } from 'client-website-ts-library/types';
import { Config } from 'client-website-ts-library/services';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import FranchiseAgentTestimonialCycler from '@/components/FranchiseAgentTestimonialCycler.vue';
import WelcomeModal from '../components/WelcomeModal.vue';

@Component({
  components: {
    VideoPlayer,
    Embeddable,
    Form,
    FranchiseOpportunityCard,
    FranchiseAgentTestimonialCycler,
    WelcomeModal,
    ItemCycler,
  },
  directives: {
    'observe-visibility': ObserveVisibility,
  },
})
export default class Franchise extends Mixins(View) {
    isFranchisingBooklet = false;

    isAHandbookForSuccess = false;

    isPoochParty = false;

    circlesActive = false

    isBrandVideo = false;

    isInRealEstateVideo = false;

    isBacktoBasics = false;

    isPrincipalTestimonial = false;

    isTakeYourCareerToTheNextLevel = false;

    isWeAreHereToHelpYou = false;

    isExplorationDay = false;

    isBeASharer = false;

    isCourageAnxietyFear = false;

    isBusinessIsATeamSport = false;

    isAndrewActonActnow = false;

    isExplorationDay2023 = false;

    private formData: FormConstructorData | null = null;

    private cyclerImages: ItemCyclerSlide[] = [
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/1', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/2', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/3', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/4', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/5', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/6', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/7', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/8', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/9', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
      new ItemCyclerImageSlide({ source: '/assets/images/franchise/slider/10', formats: ['webp', 'avif', 'jpg'], fallback: 'jpg' }),
    ];

    private readonly videoEmbed: EmbeddedContent = {
      Type: EmbeddedContentType.Youtube,
      EmbedId: 'H9hgA45NXPA',
    }

    private readonly videoEmbedExplorePropertyBrandVideo: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '764429278',
    }

    private readonly videoEmbedInRealEstate: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '764428632',
    }

    private readonly videoEmbedBacktoBasics: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '764791241',
    }

    private readonly videoEmbedPrincipalTestimonial: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '772298850',
    }

    private readonly videoEmbedTakeYourCareerToTheNextLevel: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '772297820',
    }

    private readonly videoEmbedWeAreHereToHelpYou: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '780586549',
    }

    private readonly videoEmbedExplorationDay: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '789557544',
    }

    private readonly videoEmbedBeASharer: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '789560403',
    }

    private readonly videoEmbedCourageAnxietyFear: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '835265868',
    }

    private readonly videoEmbedBusinessIsATeamSport: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '835265278',
    }

    private readonly videoEmbedAndrewActonActnow: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '835265687',
    }

    private readonly videoExplorationDay2023: EmbeddedContent = {
      Type: EmbeddedContentType.Vimeo,
      EmbedId: '859639928',
    }

    private readonly poochParty: EmbeddedContent = {
      Type: EmbeddedContentType.Youtube,
      EmbedId: 'jldTsqEufVY',
    }

    private formContext: Context = {
      ClientWebsiteId: Config.Website.Id,
      Items: [
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: 'c7894578-a495-4cc1-a0b3-656f0684b2c0',
        },
      ],
    }

    private availableOpportunities: string[] = [
      'Port Douglas',
      'Innisfail',
      'Ingham',
      'Mt Isa',
      'Charters Towers',
      'Kirwan (Townsville)',
      'Bowen',
      'Mission Beach',
      'Rockhampton',
      'Yeppoon',
      'Gladstone',
      'Tannum Sands',
      'Agnes Waters/1770',
      'Hervey Bay',
      'Maryborough',
      'Gympie',
      'Sunshine Coast',
      'Brisbane region',
      'Gold Coast region',
      'Toowoomba',
    ];

    downloadFranchisingBooklet() {
      this.isFranchisingBooklet = true;
    }

    downloadAHandbookForSuccess() {
      this.isAHandbookForSuccess = true;
    }

    mounted() {
      if (this.$route.hash === '#brand') {
        this.isBrandVideo = true;
      }

      if (this.$route.hash === '#inrealestate') {
        this.isInRealEstateVideo = true;
      }

      if (this.$route.hash === '#backtobasics') {
        this.isBacktoBasics = true;
      }

      if (this.$route.hash === '#principaltestimonial') {
        this.isPrincipalTestimonial = true;
      }

      if (this.$route.hash === '#takeyourcareertothenextlevel') {
        this.isTakeYourCareerToTheNextLevel = true;
      }

      if (this.$route.hash === '#weareheretohelpyou') {
        this.isWeAreHereToHelpYou = true;
      }

      if (this.$route.hash === '#explorationday') {
        this.isExplorationDay = true;
      }

      if (this.$route.hash === '#beasharer') {
        this.isBeASharer = true;
      }

      if (this.$route.hash === '#courageanxietyfear') {
        this.isCourageAnxietyFear = true;
      }

      if (this.$route.hash === '#businessisateamsport') {
        this.isBusinessIsATeamSport = true;
      }

      if (this.$route.hash === '#andrewactonactnow') {
        this.isAndrewActonActnow = true;
      }

      if (this.$route.hash === '#explorationday2023') {
        this.isExplorationDay2023 = true;
      }

      if (this.$route.hash === '#ahandbookforsuccess') {
        this.isAHandbookForSuccess = true;
      }

      if (this.$route.hash === '#poochparty') {
        this.isPoochParty = true;
      }

      if (this.$route.hash === '#testimonials') {
        const element = document.getElementById('testimonials');
        const top = element!.offsetTop;
        window.scrollTo(0, top - 50);
      }

      this.formData = new FormConstructorData([
        {
          Type: ContextItemType.EnquiryTargetProfile,
          Id: '42a8d902-1f58-4d82-aa3b-a4808f3e2914',
        },
      ]);
    }
}
